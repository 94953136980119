import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/site_logo/site_logo_3.svg";
import MobileMenu from "../MobileMenu/MobileMenu";
import icon1 from '../../images/icons/icon_mail.svg';

const Header = (props) => {
  const [mobailActive, setMobailState] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const spanStyle = {
    backgroundColor: hover ? "background-image: linear-gradient(to right, #3893FF, #7CACFC, #5086F8) !important;" : "transparent",
  };

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className="site_header site_header_1">
      <div className="header_top text-center">
        <div className="container">
          <p className="m-0">
            <strong>Limited Seats</strong> : Kodo Program Commences On
            <b> March 03, 2025</b>.{" "}
            <Link onClick={ClickHandler} to="/contact">
              <u>Apply Now</u> <i className="fa-solid fa-angle-right"></i>
            </Link>
          </p>
        </div>
      </div>
      <div
        className={`header_bottom stricky  ${
          isSticky ? "stricked-menu stricky-fixed" : ""
        }`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-3 col-md-4 col-5">
              <div className="site_logo">
                <Link onClick={ClickHandler} className="site_link" to="/">
                  <img
                    src={logo}
                    alt="Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template"
                  />
                </Link>
                <div className="badge bg-danger-subtle text-danger">
                  Exclusive
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-2 col-2">
              <nav className="main_menu navbar navbar-expand-lg">
                <div
                  className="main_menu_inner collapse navbar-collapse justify-content-lg-center d-none d-lg-block"
                  id="main_menu_dropdown"
                >
                  <ul className="main_menu_list unordered_list justify-content-center">
                    <li className="">
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/"
                        // id="home_submenu"
                        // role="button"
                        // data-bs-toggle="dropdown"
                        // aria-expanded="false"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        About Us
                      </Link>
                    </li>

                    <li className="dropdown">
                      <Link
                        onClick={ClickHandler}
                        className="nav-link"
                        to="/Service-Home"
                        // id="home_submenu"
                        // role="button"
                        // data-bs-toggle="dropdown"
                        // aria-expanded="false"
                      >
                        Service
                      </Link>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="home_submenu"
                      >
                        {/* <li><Link onClick={ClickHandler} to="/Service-Home">Service Home</Link></li> */}
                        <li>
                          <Link onClick={ClickHandler} to="/service-XnovaQ">
                            Direct Hire Staffing
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/Contingent-Staffing"
                          >
                            Contingent Staffing
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={ClickHandler}
                            to="/Recruitment-Process"
                          >
                            Recruitment Process Outsourcing
                          </Link>
                        </li>
                        {/* <li><Link onClick={ClickHandler} to="">Near Shore Staffing</Link></li> */}
                      </ul>
                    </li>
                    {/* <li className="">
                                            <Link onClick={ClickHandler} className="nav-link" to="/home" id="home_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Kodo
                                            </Link>
                                        </li> */}
                    {/* <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="company_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Company
                                            </Link>
                                            <div className="dropdown-menu mega_menu_wrapper" aria-labelledby="company_submenu">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-9">
                                                            <div className="megamenu_pages_wrapper mb-5">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/about">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon1} alt="Wifi SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">About Us</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Learn more about Techco
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/pricing">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon2} alt="Dollar SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Our Pricing</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Streamlined Pricing
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/portfolio">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon3} alt="Chart SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Portfolio</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Explore our all overview
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/portfolio_details/Explore-Our-IT-Solutions">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon4} alt="Event Tag SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Portfolio Details</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Explore our work overview
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/team">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon5} alt="User Check SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Team</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                We are friendly Join our team
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/team-single/Atticus-Sterling">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon6} alt="Users SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Team Details</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                We are friendly Join our team
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon7} alt="Pen SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Services</small>
                                                                            </span>
                                                                            <small className="description mb-0">
                                                                                Happy to help you!
                                                                            </small>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service-single/IT-Management-Services">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon7} alt="Pen SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Service Details</small>
                                                                            </span>
                                                                            <small className="description mb-0">
                                                                                Happy to help you!
                                                                            </small>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className="btns_group p-0 unordered_list justify-content-start">
                                                                <li>
                                                                    <Link onClick={ClickHandler} className="btn btn-primary" to="/contact">
                                                                        <span className="btn_label" data-text="Free Consultation">Free Consultation</span>
                                                                        <span className="btn_icon">
                                                                            <i className="fa-solid fa-arrow-up-right"></i>
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <div className="review_short_info_2">
                                                                        <div className="review_admin_logo">
                                                                            <img src={icon8} alt="Client Logo" />
                                                                        </div>
                                                                        <div className="review_info_content">
                                                                            <ul className="rating_block unordered_list">
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                            </ul>
                                                                            <div className="review_counter">From <b>200+</b> reviews</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="review_short_info_2">
                                                                        <div className="review_admin_logo">
                                                                            <img src={icon9} alt="Client Logo" />
                                                                        </div>
                                                                        <div className="review_info_content">
                                                                            <ul className="rating_block unordered_list">
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                            </ul>
                                                                            <div className="review_counter">From <b>200+</b> reviews</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="site_author bg-primary">
                                                                <div className="author_box">
                                                                    <div className="author_image bg-light">
                                                                        <img src={icon10} alt="Site Author" />
                                                                    </div>
                                                                    <div className="author_box_content">
                                                                        <h3 className="author_name text-white">Maverick Phoenix</h3>
                                                                        <span className="author_designation text-white">CEO At Techco</span>
                                                                    </div>
                                                                    <div className="quote_icon">
                                                                        <img src={icon11} alt="Quote Icon" />
                                                                    </div>
                                                                </div>
                                                                <p className="mb-0 text-white">
                                                                    As a CEO at Techco  I have been voice crying in the wilderness,  trying to make requirements clear, use every minute to deliver the  result, and not reinvent the wheel. Here at Techco, I made that possible  for the clients.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                    {/* <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="portfolio_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Portfolio
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="portfolio_submenu">
                                                <li><Link onClick={ClickHandler} to="/portfolio">Portfolio</Link></li>
                                                <li><Link onClick={ClickHandler} to="/portfolio_details/Explore-Our-IT-Solutions">Portfolio Details</Link></li>
                                            </ul>
                                        </li> */}
                    <li>
                      <Link onClick={ClickHandler} to="/course">
                        Courses
                      </Link>
                    </li>

                    {/* <div className="dropdown-menu mega_menu_wrapper" aria-labelledby="company_submenu">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-9">
                                                            <div className="megamenu_pages_wrapper mb-5">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/about">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon1} alt="Wifi SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">About Us</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Learn more about Techco
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/pricing">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon2} alt="Dollar SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Our Pricing</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Streamlined Pricing
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/portfolio">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon3} alt="Chart SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Portfolio</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Explore our all overview
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/portfolio_details/Explore-Our-IT-Solutions">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon4} alt="Event Tag SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Portfolio Details</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                Explore our work overview
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/team">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon5} alt="User Check SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Team</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                We are friendly Join our team
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/team-single/Atticus-Sterling">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon6} alt="Users SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Team Details</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                                We are friendly Join our team
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon7} alt="Pen SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Services</small>
                                                                            </span>
                                                                            <small className="description mb-0">
                                                                                Happy to help you!
                                                                            </small>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/service-single/IT-Management-Services">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon7} alt="Pen SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Service Details</small>
                                                                            </span>
                                                                            <small className="description mb-0">
                                                                                Happy to help you!
                                                                            </small>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className="btns_group p-0 unordered_list justify-content-start">
                                                                <li>
                                                                    <Link onClick={ClickHandler} className="btn btn-primary" to="/contact">
                                                                        <span className="btn_label" data-text="Free Consultation">Free Consultation</span>
                                                                        <span className="btn_icon">
                                                                            <i className="fa-solid fa-arrow-up-right"></i>
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <div className="review_short_info_2">
                                                                        <div className="review_admin_logo">
                                                                            <img src={icon8} alt="Client Logo" />
                                                                        </div>
                                                                        <div className="review_info_content">
                                                                            <ul className="rating_block unordered_list">
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                            </ul>
                                                                            <div className="review_counter">From <b>200+</b> reviews</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="review_short_info_2">
                                                                        <div className="review_admin_logo">
                                                                            <img src={icon9} alt="Client Logo" />
                                                                        </div>
                                                                        <div className="review_info_content">
                                                                            <ul className="rating_block unordered_list">
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                                <li><i className="fa-solid fa-star fa-fw"></i></li>
                                                                            </ul>
                                                                            <div className="review_counter">From <b>200+</b> reviews</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="site_author bg-primary">
                                                                <div className="author_box">
                                                                    <div className="author_image bg-light">
                                                                        <img src={icon10} alt="Site Author" />
                                                                    </div>
                                                                    <div className="author_box_content">
                                                                        <h3 className="author_name text-white">Maverick Phoenix</h3>
                                                                        <span className="author_designation text-white">CEO At Techco</span>
                                                                    </div>
                                                                    <div className="quote_icon">
                                                                        <img src={icon11} alt="Quote Icon" />
                                                                    </div>
                                                                </div>
                                                                <p className="mb-0 text-white">
                                                                    As a CEO at Techco  I have been voice crying in the wilderness,  trying to make requirements clear, use every minute to deliver the  result, and not reinvent the wheel. Here at Techco, I made that possible  for the clients.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                    {/* </li> */}
                    {/* <li className="dropdown">
                                            <li><Link onClick={ClickHandler} className="nav-link" to="/service-XnovaQ" role="button" data-bs-toggle="dropdown" aria-expanded="false">Service</Link></li>
                                            <ul className="dropdown-menu" aria-labelledby="home_submenu">
                                                <li><Link onClick={ClickHandler} to="/">IT Solution</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home_software_company">Software Company</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home_business_consulting">Business Consulting</Link></li>
                                            </ul>
                                        </li> */}

                    {/* <li className="">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="pages_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Pages
                                            </Link>
                                            
                                            <ul className="dropdown-menu" aria-labelledby="pages_submenu">
                                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                                <li className="dropdown">
                                                    <Link onClick={ClickHandler} className="nav-link" to="/" id="blog_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Blogs
                                                    </Link>
                                                    <ul className="dropdown-menu" aria-labelledby="blog_submenu">
                                                        <li><Link onClick={ClickHandler} to="/blog">Our Blogs</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-single/How-Our-Software-Solutions-Drive-Insights.">Blog Details</Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link onClick={ClickHandler} to="/contact">Help Center</Link></li>
                                                <li><Link onClick={ClickHandler} to="/">Careers <small className="badge bg-danger-subtle text-danger">We’re Hiring</small></Link></li>
                                            </ul>
                                        </li> */}
                    {/* <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li> */}
                  </ul>
                </div>
              </nav>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-5">
              <ul className="header_btns_group unordered_list justify-content-end d-flex flex-row gap-2">
                <li>
                  <button
                    className="mobile_menu_btn"
                    onClick={() => setMobailState(!mobailActive)}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_menu_dropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="far fa-bars"></i>
                  </button>
                </li>
                <li>
                  <Link
                    onClick={ClickHandler}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="btn nav-button"
                    to="/kodo-program"
                  >
                     <span className="btn_label" style={spanStyle} data-text={isMobile ? "Kodo" : "Career Program"}>
      <span className={isMobile ? "mobile-label" : "desktop-label"} style={spanStyle}>
        {isMobile ? "Kodo" : "Career Program"}
      </span>
    </span>
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={ClickHandler}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="btn d-md-none d-lg-block  nav-button"
                    to="/lets-talk"
                  >
                    <span
                      className="btn_label"
                      style={spanStyle}
                      data-text="Let's Talk"
                    >
                      Let's Talk
                    </span>
                    <span className="btn_icon ms-2" style={spanStyle}>
                    <img src={icon1} alt="mail"/>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mobail-wrap">
          <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
            <div className="xb-header-menu-scroll">
              <div
                className="xb-menu-close xb-hide-xl xb-close"
                onClick={() => setMobailState(!mobailActive)}
              ></div>
              <nav className="xb-header-nav">
                <MobileMenu />
              </nav>
            </div>
          </div>
          <div
            className="xb-header-menu-backdrop"
            onClick={() => setMobailState(false)}
          ></div>
        </div>
      </div>
    </header>
  );
};

export default Header;
